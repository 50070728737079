<template>
  <div id="app">
    <TwentyTwenty
        before="//placehold.it/600x600/E8117F/FFFFFF"
        after="//placehold.it/600x600/CCCCCC/FFFFFF" />
  </div>
</template>

<script>
import 'vue-twentytwenty/dist/vue-twentytwenty.css';
import TwentyTwenty from 'vue-twentytwenty';

export default {
  name: 'App',
  components: {
    TwentyTwenty
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
